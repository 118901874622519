




































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ComponentType, ViewItem } from '@/models'
import * as ComponentTypes from '@/components/componentTypes'

@Component({})
export default class ComponentCreateDialog extends Vue {
  /** Dialog open value */
  @Prop({ type: Boolean, default: true }) value !: boolean
  /** Current Environment ID */
  @Prop({ type: String, required: true }) environmentId !: string
  /** Current component type name */
  @Prop({ type: String, required: true }) componentTypeName !: string
  /** Current component type name */
  @Prop({ type: Number, default: 1280 }) width !: number

  get open () {
    return this.value
  }

  set open (v) {
    this.$emit('input', v)
  }

  handleCreate (element : any) {
    this.$emit('input', false)
    this.$emit('create', element)
    return this.$store.dispatch('snackbar/showSnackbar', {
      text: `${this.componentType.titleSingle} creado con éxito!`,
      color: ''
    })
  }

  handleError (e : any) {
    return this.$store.dispatch('snackbar/showSnackbar', {
      text: 'Error: ' + e.message,
      color: 'error'
    })
  }

  /** Current component type */
  get componentType () : ComponentType<any> {
    return (ComponentTypes as Record<string, ComponentType<any>>)[this.componentTypeName]
  }
}
